import { Switch, Route } from 'react-router-dom';

// Components
import { LandingPage, Contact, Work, Services, NotFound } from '../index';

export function Main(){
	return(
		<Switch>
			<Route exact path="/" component={ LandingPage } />
			<Route path="/contact" component={ Contact } />
			<Route path="/work" component={ Work } />
			<Route path="/services" component={ Services } />
			<Route path="*" component={ NotFound } />
		</Switch>
	)
};