import { NavLink } from 'react-router-dom';
import { IoIosMail, IoIosHome } from 'react-icons/io';

export function Footer(){
	return(
		<div className="footer">
			<div className="footer-info">
				<div className="footer-logo">
					<h2>Shumunov Solutions</h2>
				</div>
				<div className="company-contact">
					{/* Contact Icons */}
					<IoIosMail className="icon email-icon" />
					<a href="mailto:info@ShumunovSolutions.com">info@ShumunovSolutions.com</a>
					{/* <IoIosHome className="icon location-icon" /> */}
					{/* Social Icons */}
				</div>
			</div>
			<nav className="footer-nav">
				<NavLink to="/" exact activeClassName="active">Home</NavLink>
				<NavLink to="/contact" activeClassName="active">Contact</NavLink>
				<NavLink to="/services" activeClassName="active">Services</NavLink>
				<NavLink to="/work" activeClassName="active">Work</NavLink>
			</nav>
			<p className="copyright-text">Copyright &copy; Shumunov Solutions 2021. All Rights Reserved</p>
		</div>
	)
}

