import { Client, ScrolllerLogo, WorkExample } from './index';

export function Work(){
	return(
		<div className="projects-body main-container">
			<h2>Current Clients</h2>
			<Client
				clientSiteURL={ 'http://kleinfirmpllc.com/' }
				extraClassName={ 'klein-firm' }
				extraInfo={ 'Upcoming Redesign' }
				imgSRC={ 'klein_firm_logo.svg' }
				imgInfo={ 'Klein Firm PLLC Logo' }
			/>
			<Client
				clientSiteURL={ 'http://www.yourwiredsolutions.com/' }
				extraInfo={ 'Under Development' }
				imgSRC={ 'yws_logo.svg' }
				imgInfo={ 'Your Wired Solutions Logo' }
			/>
			<h2>Previous Clients</h2>
			<ScrolllerLogo />
			<Client
				extraClassName="serendip-logo"
				clientSiteURL={ 'https://goserendip.com/' }
				imgSRC={ 'serendip_logo.png' }
				imgInfo={ 'Serendip Logo' }
			/>
			<Client
				clientSiteURL={ 'http://www.jkunlimitedservices.com/' }
				imgSRC={ 'jk_unlimited_logo.svg' }
				imgInfo={ 'JK Unlimited Services Logo' }
			/>
			<Client
				clientSiteURL={ 'https://www.papergoodswarehouse.com/' }
				imgSRC={ 'PGW_logo.svg' }
				imgInfo={ 'Paper Goods Warehouse Logo' }
			/>
			<Client
				clientSiteURL={ 'https://www.neuronpublishing.com/' }
				imgSRC={ 'neuron.jpeg' }
				imgInfo={ 'Neuron Publishing LLC' }
			/>
			<Client
				clientSiteURL={ 'https://lovebookonline.com/' }
				imgSRC={ 'lovebook_logo.png' }
				imgInfo={ 'Lovebok Online Logo' }
			/>
			<Client
				clientSiteURL={ 'https://www.printhq.com/' }
				imgSRC={ 'Progressive_Solutions_Logo.png' }
				imgInfo={ 'Progressive Solutions Logo' }
			/>
			<Client
				extraClassName="shq-logo"
				clientSiteURL={ 'https://www.stationeryhq.com/' }
				imgSRC={ 'SHQLogo.svg' }
				imgInfo={ 'StationeryHQ Logo' }
			/>
			<Client
				clientSiteURL={ 'https://www.frecklebox.com/' }
				imgSRC={ 'frecklebox_logo.png' }
				imgInfo={ 'Frecklebox Logo' }
			/>
			<h2>Work Examples</h2>
			<WorkExample
				projectName={ 'Paper Goods Warehouse Website' }
				projectURL={ 'https://www.papergoodswarehouse.com/' }
				imgSRC={ 'Papergoodswarehouse_website.png' }
				imgInfo={ 'Paper Goods Warehouse Website Screenshot' }
			/>
			<WorkExample
				projectName={ 'StationeryHQ Site Update' }
				projectURL={ 'https://www.stationeryhq.com/' }
				imgSRC={ 'stationeryhq.png' }
				imgInfo={ 'StationeryHQ Website Screenshot' }
			/>
			<WorkExample
				projectName={ 'Frecklebox Customization Engine' }
				projectURL={ 'https://www.frecklebox.com/collections/hard-cover-storybooks/products/zoo-book-soft-cover' }
				imgSRC={ 'Frecklebox_personalization_engine.png' }
				imgInfo={ 'Frecklebox Customization Engine Screenshot' }
			/>
			<WorkExample
				projectName={ 'Frecklebox Site Update' }
				projectURL={ 'https://www.frecklebox.com/' }
				imgSRC={ 'frecklebox.png' }
				imgInfo={ 'Frecklebox Website Screenshot' }
			/>
		</div>
	);
}