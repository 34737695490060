import { NavLink } from 'react-router-dom';

export function Header(){
	return(
		<div className="header">
			<div className="header-logo">
				<h1>Shumunov Solutions</h1>
			</div>
			<nav className="header-nav">
				<NavLink to="/" exact activeClassName="active">Home</NavLink>
				<NavLink to="/contact" activeClassName="active">Contact</NavLink>
				<NavLink to="/services" activeClassName="active">Services</NavLink>
				<NavLink to="/work" activeClassName="active">Work</NavLink>
			</nav>
		</div>
	);
}
