import { useState, useEffect } from 'react';
import { StyledPipe } from './Pipe.styled';


export function Pipe (){
	const [show, setShow] = useState(false);

	useEffect(() => {
		const toggleShow = () => setInterval(() => setShow(!show), 600);
		const timer = toggleShow();
		return () => clearInterval(timer);
	}, [show]);

	const shouldShow = show ? 'hidden': 'visible';

	return(
		<StyledPipe
			className="pipe"
			style={{
				visibility: shouldShow,
			}}>
		</StyledPipe>
	);
}
