import { NavLink } from 'react-router-dom';
import { bool, func } from 'prop-types';
import { StyledMenu } from './Menu.styled';

export function Menu({ open, setOpen, ...props }){

	const isHidden = open ? true : false;
	const tabIndex = isHidden ? 0 : -1;

	return (
	<StyledMenu className="side-nav" open={open} aria-hidden={!isHidden} {...props}>
		<NavLink to="/" exact activeClassName="active" tabIndex={tabIndex} onClick={() => setOpen(!open)}>
			<span role="img" aria-hidden="true">&#x1F3E2;</span>
			Home
		</NavLink>
		<NavLink to="/contact" activeClassName="active" tabIndex={tabIndex} onClick={() => setOpen(!open)}>
			<span role="img" aria-hidden="true">&#x1f4e9;</span>
			Contact
		</NavLink>
		<NavLink to="/services" activeClassName="active" tabIndex={tabIndex} onClick={() => setOpen(!open)}>
			<span role="img" aria-hidden="true">&#x1f4b8;</span>
			Services
		</NavLink>
		<NavLink to="/Work" activeClassName="active" tabIndex={tabIndex} onClick={() => setOpen(!open)}>
			<span role="img" aria-hidden="true">&#x1F4BC;</span>
			Work
		</NavLink>
	</StyledMenu>
	)
}

Menu.propTypes = {
	open: bool.isRequired,
	setOpen: func.isRequired,
}