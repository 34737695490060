import React, { useEffect, useState, useRef } from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import './App.scss';

// Google Analytics & History
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import FocusLock from 'react-focus-lock';

// Components
import { Burger, Menu, Header, Main, Footer } from './components';

// Hooks
import { useOnClickOutside } from './hooks/useOnClickOutside';

const history = createBrowserHistory();
history.listen(location => {
	ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname)
});

function App() {
	const [open, setOpen] = useState(false);
	const node = useRef();
	const menuId = "main-menu";

	useOnClickOutside(node, () => setOpen(false));

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
	});

	return (
		<Router history={ history }>
			<ThemeProvider theme={theme}>
				<div ref={node}>
					<FocusLock disabled={!open}>
						<Burger open={open} setOpen={setOpen} aria-controls={menuId} />
						<Menu open={open} setOpen={setOpen} id={menuId} />
					</FocusLock>
				</div>
				<Header />
				<Main />
				<Footer />
			</ThemeProvider>
		</Router>
	);
}

export default App;
