import { useState } from 'react';
import { ContactForm } from './index';
// FormDebug

// TODO: Figure out Validation & Error handling

const nameValidation = (fieldName, fieldValue) => {
	if (fieldValue.trim() === '') {
		return `${fieldName} is required`;
	}
	if (/[^a-zA-Z -]/.test(fieldValue)) {
		return 'Invalid characters';
	}
	if (fieldValue.trim().length < 3) {
		return `${fieldName} needs to be at least three characters`;
	}
	return null;
};

const emailValidation = email => {
	if (
	/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
		email,
	)) {
		return null;
	}
	if (email.trim() === '') {
		return 'Email is required';
	}
	return 'Please enter a valid email';
};

// strip non-digits (1-2 => 12 | 5.6 => 56)
// run regex test and update valid state to show validation
function checkPhoneNumber(element){
	// regex to check phone number against
	const phoneRegex = new RegExp('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$', 'im');

	const userInput = element.value,
		validNumber = phoneRegex.test(userInput);
	if (element.value !== ''){
		if (validNumber){
			this.setState({ valid: true });
		} else {
			this.setState({ valid: false });
		}
	} else {
		this.setState({ valid: null });
	}
}

const messageValidation = message => {
	if (message.trim() === '') {
		return 'Message is required';
	}
	return null;
};

const validate = {
	name: name => nameValidation('Name', name),
	companyName: name => nameValidation('Company Name', name),
	email: emailValidation,
	number: checkPhoneNumber,
	message: messageValidation
};

const initialValues = {
	name: null,
	companyName: null,
	email: null,
	phone: null,
	message: null,
};

export function Contact(){

	const [values, setValues] = useState(initialValues);
	const [errors, setErrors] = useState({});
	const [touched, setTouched] = useState({});

	// change event handler
	const handleChange = evt => {
		const { name, value: newValue, type } = evt.target;

		// keep number fields as numbers
		const value = type === 'number' ? +newValue : newValue;

		// save field values
		setValues({
			...values,
			[name]: value,
		});

		// was the field modified
		setTouched({
			...touched,
			[name]: true,
		});
	};

	const handleBlur = evt => {
		const { name, value } = evt.target;

		// remove whatever error was there previously
		const { [name]: removedError, ...rest } = errors;

		// check for a new error
		const error = validate[name](value);

		// // validate the field if the value has been touched
		setErrors({
			...rest,
			...(error && { [name]: touched[name] && error }),
		});
	};

	// form submit handler
	// const handleSubmit = evt => {
	// 	evt.preventDefault();
	//
	// 	// validate the form
	// 	const formValidation = Object.keys(values).reduce(
	// 		(acc, key) => {
	// 			const newError = validate[key](values[key]);
	// 			const newTouched = { [key]: true };
	// 			return {
	// 				errors: {
	// 					...acc.errors,
	// 					...(newError && { [key]: newError }),
	// 				},
	// 				touched: {
	// 					...acc.touched,
	// 					...newTouched,
	// 				},
	// 			};
	// 		},
	// 		{
	// 			errors: { ...errors },
	// 			touched: { ...touched },
	// 		},
	// 	);
	// 	setErrors(formValidation.errors);
	// 	setTouched(formValidation.touched);
	//
	// 	if (
	// 		!Object.values(formValidation.errors).length && // errors object is empty
	// 		Object.values(formValidation.touched).length ===
	// 			Object.values(values).length && // all fields were touched
	// 		Object.values(formValidation.touched).every(t => t === true) // every touched field is true
	// 	) {
	// 		alert(JSON.stringify(values, null, 2));
	// 	}
	// };

	// function sendEmail() {
	// 	var name = $("#name"),
	// 		company = $('#company'),
	// 		phone = $('#telephone'),
	// 		email = $("#email"),
	// 		message = $("#message");
	//
	// 	if (isNotEmpty(name) && isNotEmpty(email) && isNotEmpty(message)) {
	// 		$.ajax({
	// 			url: 'sendEmail.php',
	// 			method: 'POST',
	// 			dataType: 'json',
	// 			data: {
	// 				name: name.val(),
	// 				company: company.val(),
	// 				phone: phone.val(),
	// 				email: email.val(),
	// 				subject: subject.val(),
	// 				message: message.val()
	// 			}
	// 		}).always(function(response){
	// 			// console.log('all', response)
	// 			$('.contact-form')[0].reset();
	// 			$('.contact-form input, .contact-form textarea').removeClass('valid').removeClass('not-valid');
	// 			$('.contact-form-container .sent-notification').text('Your email has been sent! We value your message and will contact you within 24-48 hours');
	// 		});
	// 	}
	// }
	//
	// function isNotEmpty(formField) {
	//
	// 	if (formField.val() == '') {
	// 		formField.css('border', '3px solid red');
	// 		return false;
	// 	} else {
	// 		formField.css('border', '');
	// 	}
	//
	// 	return true;
	// }

	// $('.contact-form').on('submit', function(e, triggered){
	//
	// 	e.preventDefault();
	//
	// 	trimInputValues();
	//
	// 	if ('checkValidity' in this && $.isFunction(this.checkValidity)) {
	// 		if (!this.checkValidity()){
	// 			if ( triggered === false){
	// 				$(this).trigger('submit', true);
	// 			}
	// 			return false;
	// 		}
	// 		sendEmail();
	// 	}
	// });
	//
	// function trimInputValues(){
	//
	// 	var formInputs = $('.contact-form :input');
	//
	// 	for (var i = 0; i < formInputs.length; i++) {
	//
	// 		if (formInputs[i].type === 'text' || formInputs[i].type === 'textarea') {
	//
	// 			var formInputValue = formInputs[i].value.replace(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|(?:\ud83c[\ude01-\ude02])|\ud83c\ude1a|\ud83c\ude2f|(?:\ud83c[\ude32-\ude3a])|(?:\ud83c[\ude50-\ude51])|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g, '');
	//
	// 			formInputs[i].value = $.trim(formInputValue);
	//
	// 		}
	// 	}
	// }

	return(
		<div className="contact-body main-container">
			<h2>Contact us</h2>
			<p>You've got questions, we've got answers. Fill out the contact form below and our team will get back to you as quickly as possible.</p>
			<div>
				<div className="form-fields-required">Fields marked with an
					<span className="required-symbol"> *</span> are required
				</div>
				<div className="error">

				</div>
				<ContactForm
					validate={ validate }
					initialValues={ initialValues }
				/>
				{/*
					Only Use for Dev Testing
					<FormDebug values={ values } errors={ errors } touched={ touched } />
				*/}
			</div>
		</div>
	);
}