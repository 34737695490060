import { useState, useEffect } from 'react';

const introMessage = `<span class="blue">programInit</span>();<br/><br/>
	<span class="purple">var</span> yourProblems
		<span class="white">=</span> websites
		<span class="blue">||</span> webApps
		<span class="blue">||</span> mobileApps
		<span class="blue">||</span> consulting
		<span class="blue">||</span> taskAutomation;<br/><br/>
	yourProblems <span class="red">require</span> uniqueSolutions;<br/><br/>
	cookieCutter <span class="red">!</span>inOurVocabulary;<br/><br/>
	<span class="purple-text">function</span> <span class="blue">workWithShumunovSolutions</span>(){<br/>
		<span class="purple indent">return</span> results && happyCustomers;<br/>
		<span class="indent">// =]</span><br/>
	}<br/><br/>
	<span class="blue">workWithShumunovSolutions</span>();<br/><br/>
	We listen to your needs to deliver the products and services your company desires.<br/><br/>
	Contact us to see how we can help your business grow!`;

export function TypeWriter (){
	const [myIntro, setMyIntro] = useState({ characters: '', charCounter: 0 });

	const { characters, charCounter } = myIntro;

	const updateIntro = () => {
		const updatedIntro = {
			...myIntro,
			charCounter: charCounter + 1,
			characters: characters + introMessage[charCounter]
		}
		setMyIntro(updatedIntro);
	}

	const isIntroCompleted = introMessage.length === characters.length;

	useEffect(() => {
		const timer = setInterval(() => !isIntroCompleted && updateIntro(), 50)
		return () => clearInterval(timer);
	}, [myIntro]);

	return <span className="typewriter" dangerouslySetInnerHTML={{__html: characters}}></span>
}