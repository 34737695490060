import { PriceComparison } from './index';

export function Services(){
	return(
		<div className="services-body main-container">
			<h2>Services</h2>
			<p className="tab">Keeping up with the lastest trends in technology can be complicated and time consuming. We specialize in technology so you can </p>
			<h3>General Development Consulting</h3>
			<p className="tab">Looking to take the next step, but don't know how to bridge that divide? We can take a look at your site or application and offer constructive feedback on areas where we believe we can optimize the look feel and performance of your site. Depeding on the technologies we use, we may be able to step in and take out any bugs you may be experiencing.</p>
			<PriceComparison
				perHour={ true }
				columnOnePop={ true }
				columnTwoPop={ false }
				columnThreePop={ false }
				priceTitle={ 'Consulting' }
				planOnePrice={ '90' }
				planOneName={ 'Development' }
				planTwoPrice={ '120' }
				planTwoName={ 'Consultant' }
				planThreePrice={ '150' }
				planThreeName={ 'Management' }
				featureA={ 'Development' }
				featureB={ 'Task Management' }
				featureC={ 'Biweekly meetings' }
				featureD={ 'Analytics Review' }
				featureE={ 'Dev Mentorship' }
				featureF={ 'Code Review' }
			/>
			<h3>Web Development</h3>
			<p className="tab">We specialize in creating beautiful interactive websites that will wow your customers and make the user experience one of the most enjoyable.</p>
			<PriceComparison
				perHour={ false }
				columnOnePop={ false }
				columnTwoPop={ true }
				columnThreePop={ false }
				priceTitle={ 'Websites' }
				planOnePrice={ '3000' }
				planOneName={ 'Basic' }
				planTwoPrice={ '7500' }
				planTwoName={ 'Professional' }
				planThreePrice={ '15000+' }
				planThreeName={ 'Enterprise' }
				featureA={ '1 - 3 Pages' }
				featureA2={ '4 - 6 Pages' }
				featureA3={ '7 - 10 Pages' }
				featureB={ 'Custom Design' }
				featureC={ 'SSL' }
				featureD={ 'Code Review' }
				featureE={ 'Dev Mentorship' }
				featureF={ 'eComm Platform' }
			/>
			{/* <p>We have a number</p>
			<p>A sleek site that is 1 page starts at only $1,000!</p>
			<p>websites up to 6 pages are $2,500</p>
			<p>Sites up to 10 pages are $5,000+</p>
			<p>Setting up an e-commerce platform starting from $5,000</p> */}
			<h3>Other services</h3>
			<p className="tab"><span className="bold-text">Didn't find what you were looking for?</span> No worries, we understand that your unique company has unique needs that requires a considerate approach. Feel free to contact us, describe the problem you're facing, and the desired solution. We'll work with you to make sure your vision becomes a reality</p>
			<h3>Technologies</h3>
			<p className="tab">For the tech savvy, we primarily work with technologies like React, React Native (mobile apps), JavaScript, jQuery, HTML, CSS & SASS, and php</p>
			<p className="tab">While some projects may be out of this scope, we have the ability to apply creative solutions to whatever problem you're experiencing</p>
		</div>
	);
}