export function ScrolllerLogo(){
	return(
		<div className="client-container scrolller-logo">
			<a
				href="https://scrolller.com"
				rel="noopener noreferrer"
				target="_blank"
			>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2891.85 869.89">
					<title>Scrolller logo</title>
					<text transform="translate(16.01 749.31)">s</text>
					<text transform="translate(406.86 749.63)">c</text>
					<text transform="translate(804.31 749.48)">r</text>
					<text transform="translate(1156.24 748.84)">o</text>
					<path d="M1914.13,690.47V610.38h106V756.52a13.93,13.93,0,0,1-13.91,14h-78.17a13.93,13.93,0,0,1-13.92-14Z"></path>
					<path d="M1914.13,171.63V105.57a13.93,13.93,0,0,1,13.92-13.94h78.17a13.93,13.93,0,0,1,13.91,13.94V593.15h-106Z"></path>
					<path d="M1791.09,690.63V440.33h106V756.68a13.94,13.94,0,0,1-13.92,14H1805a13.93,13.93,0,0,1-13.91-14Z"></path>
					<path d="M1791.09,171.78v-66a13.93,13.93,0,0,1,13.91-14h78.17a13.93,13.93,0,0,1,13.92,14V423.25h-106Z"></path>
					<path d="M1668,690.63V268.89h106V756.68a13.93,13.93,0,0,1-13.91,14H1682a13.94,13.94,0,0,1-13.92-14Z"></path>
					<path d="M1668,171.78v-66a13.93,13.93,0,0,1,13.92-14h78.17a13.93,13.93,0,0,1,13.91,14V251.78H1668Z"></path>
					<text transform="translate(2100.43 751.32)">e</text>
					<text transform="translate(2553.79 750.86)">r</text>
				</svg>
			</a>
		</div>
	)
}