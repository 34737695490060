import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Client, Pipe, ScrolllerLogo, Testimonial, TypeWriter } from './index';
import { IoIosAddCircle, IoIosCloseCircle } from 'react-icons/io';

// Hooks
export function LandingPage(){

	const [isActive, setIsActive] = useState(true);

	function toggleActive(){
		if (isActive === true){
			setIsActive(false);
		} else {
			setIsActive(true);
		}
	}

	return(
		<div className="landing main-container">
			<div className="landing-promo">
				<h2>Why Us?</h2>
				<h3>We only care about your success</h3>
			<p className="agency-copy">We enjoy telling stories through code by creating beautiful, interactive websites that people love to use!</p>
			</div>
			<div className="landing-header">
				<h3>Custom built</h3>
				<h2>Websites, Web Apps, and Mobile Apps</h2>
				<hr />
				<NavLink to="/services" className="learn-more">Learn More</NavLink>
			</div>
			<div className="screen">
				<code>
					<TypeWriter />
					<Pipe />
				</code>
			</div>
			<div className="code-legend-container">
				<h4>Legend</h4>
				<span
					className={`code-legend-open ${ isActive ? 'active' : '' }`}
					onClick={ toggleActive }
				>
					<IoIosAddCircle
						className="icon plus-icon"
					/>
				</span>
				<span
					className={`code-legend-close ${ isActive ? '' : 'active' }`}
					onClick={ toggleActive }
				>
					<IoIosCloseCircle
						className="icon minus-icon"
					/>
				</span>
				<div className={`code-legend ${ isActive ? '' : 'active' }`}>
					<p>
						<span className="purple-text">var</span> <span className="white-text">=</span> a container for information
					</p>
					<p>
						<span className="blue-text">||</span> <span className="white-text">=</span> or
					</p>
					<p>
						<span className="red-text">!</span> <span className="white-text">=</span> not
					</p>
					<p>
						<span className="purple-text">function</span> <span className="white-text">=</span> a set of instructions that perform a specific task
					</p>
				</div>
			</div>
			<div className="landing-clients">
				<h2>Some Of Our Clients</h2>
				<p>Brands we had & have the pleasure to work with</p>
				<ScrolllerLogo />
				<Client
					extraClassName="serendip-logo"
					clientSiteURL={ 'https://goserendip.com/' }
					imgSRC={ 'serendip_logo.png' }
					imgInfo={ 'Serendip Logo' }
				/>
				<Client
					clientSiteURL={ 'http://www.jkunlimitedservices.com/' }
					imgSRC={ 'jk_unlimited_logo.svg' }
					imgInfo={ 'JK Unlimited Services Logo' }
				/>
				<Client
					clientSiteURL={ 'http://www.shumunovsolutions.com/yws' }
					imgSRC={ 'yws_logo.svg' }
					imgInfo={ 'Your Wired Solutions Logo' }
				/>
				<Client
					clientSiteURL={ 'https://www.papergoodswarehouse.com/' }
					imgSRC={ 'PGW_logo.svg' }
					imgInfo={ 'Paper Goods Warehouse Logo' }
				/>
				<Client
					clientSiteURL={ 'https://www.neuronpublishing.com/' }
					imgSRC={ 'neuron.jpeg' }
					imgInfo={ 'Neuron Publishing LLC' }
				/>
				<Client
					clientSiteURL={ 'https://lovebookonline.com/' }
					imgSRC={ 'lovebook_logo.png' }
					imgInfo={ 'Lovebok Online Logo' }
				/>
				<Client
					extraClassName="shq-logo"
					clientSiteURL={ 'https://www.stationeryhq.com/' }
					imgSRC={ 'SHQLogo.svg' }
					imgInfo={ 'StationeryHQ Logo' }
				/>
				<Client
					clientSiteURL={ 'https://www.frecklebox.com/' }
					imgSRC={ 'frecklebox_logo.png' }
					imgInfo={ 'Frecklebox Logo' }
				/>
			</div>
			<div className="testimonials">
				<Testimonial />
			</div>
			<NavLink to="/services" className="learn-more">Learn More</NavLink>
		</div>
	)
};