import React, { Component } from 'react';
import { IoIosPerson, IoIosMail, IoIosPhonePortrait, IoMdText } from 'react-icons/io';

class ContactForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			name: '',
			phone: '',
			company: '',
			email: '',
			message: '',
			mailSent: false,
			error: null,
			buttonText: 'Submit'
		}

		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		// this.handleChange = this.handleChange.bind(this);
		// this.handleKeyUp = this.handleKeyUp.bind(this);
	}

	// handleChange(propName, event){
	// 	this.setState({
	// 		propName: event.target.value
	// 	});
	// }

	// TODO: Figure out how to properly implement validation

	// handleKeyUp(event){
	// 	const checkPhoneNumber = this.props.validate.number;
	// 	let currentTarget = event.currentTarget;
	// 	if (currentTarget.value !== ''){
	// 		if (currentTarget.id === 'phone') {
	// 			checkPhoneNumber(currentTarget.value);
	// 		} else if (currentTarget){
	// 			this.setState({ valid: true });
	// 		} else {
	// 			this.setState({ valid: false });
	// 		}
	// 	} else if (currentTarget.value === '') {
	// 		this.setState({ valid: null });
	// 	}
	// }

	resetForm = () => {
		this.setState({
			name: '',
			phone: '',
			company: '',
			email: '',
			message: '',
			mailSent: false,
			errors: null,
			touched: null,
			valid: null,
			buttonText: 'Message Sent'
		})
	}

	handleFormSubmit(event) {
		event.preventDefault();

		this.setState({
			buttonText: '...Sending'
		})

		let data = {
			name: this.state.name,
			phone: this.state.phone,
			company: this.state.company,
			email: this.state.email,
			message: this.state.message
		}

		// create a new XMLHttpRequest
		var xhr = new XMLHttpRequest();

		// get a callback when the server responds
		xhr.addEventListener('load', () => {
			// update the response state and the step
			console.log(xhr.responseText);
		});
		// open the request with the verb and the url
		xhr.open('GET', 'https://shumunovsolutions.com/api/sendEmail.php?email=' + data.email +
			'&name=' + data.name +
			'&company=' + data.company +
			'&phone=' + data.phone +
			'&message=' + data.message
		);
		// send the request
		xhr.send();
		this.resetForm();
	}

	render() {
		return(
			<form className="contact-form"
				autoComplete="off"
				onSubmit={ event => this.handleFormSubmit(event) }
			>
				<div className="input-container">
					<label className="form-label">
						Name <span className="required-symbol">*</span>
					</label>
					<input type="text" id="form-name" name="name" placeholder="Your name..."
						className="form-control"
						value={ this.state.name }
						onChange={ event => { this.setState({ name: event.target.value }) } }
					required />
					<span className="input-icon">
						<IoIosPerson className="icon user-icon" />
					</span>
					{/* onKeyUp={ event => this.handleKeyUp(event) } */}
					{/* }// { this.state.touched.name && this.state.errors.name } */}
				</div>
				<div className="input-container">
					<label className="form-label">
						Company Name
					</label>
					<input type="text" id="company" className="form-control" name="company" autoComplete="tel" placeholder="Your company name..."
						value={ this.state.company }
						onChange={ event => { this.setState({ company: event.target.value }) } }
					/>
					<span className="input-icon icon company-icon"
						aria-label="Company Emoji"
					>
						&#x1F3E2;
					</span>
					{/* onKeyUp={ event => this.handleKeyUp(event) } */}
					{/* // { this.state.touched.company && this.state.errors.company } */}
				</div>
				<div className="input-container">
					<label className="form-label">
						Email <span className="required-symbol">*</span>
					</label>
					<input type="email" id="email" name="email" placeholder="Your email..."
						className="form-control"
						value={ this.state.email }
						onChange={ event => { this.setState({ email: event.target.value }) } }
					required />
					<span className="input-icon">
						<IoIosMail className="icon email-icon" />
					</span>
					{/* onKeyUp={ event => this.handleKeyUp(event) } */}
					{/* // { this.state.touched.email && this.state.errors.email } */}
				</div>
				<div className="input-container">
					<label className="form-label">
						Phone
					</label>
					<input type="number" id="phone" className="form-control" name="phonenumber" autoComplete="tel" placeholder="Your phone number..."
						value={ this.state.phone }
						onChange={ event => { this.setState({ phone: event.target.value }) } }
					/>
					<span className="input-icon">
						<IoIosPhonePortrait className="icon phone-icon" />
					</span>
					{/* onKeyUp={ event => this.handleKeyUp(event) } */}
					{/* // { this.state.touched.phone && this.state.errors.phone } */}
				</div>
				<div className="textarea-container">
					<label className="form-label">
						Message <span className="required-symbol">*</span>
					</label>
					<textarea id="subject" name="subject" placeholder="Your message..."
						className="form-control"
						value={ this.state.message }
						onChange={ event => { this.setState({ message: event.target.value }) } }
					required></textarea>
					<span className="textarea-icon">
						<IoMdText className="icon comment-icon" />
					</span>
					{/* onKeyUp={ event => this.handleKeyUp(event) } */}
					{/* // { this.state.touched.message && this.state.errors.message } */}
				</div>
				<input type="submit"
					value={ this.state.buttonText }
				/>
				<div className="contacted">
					{ this.state.mailSent &&
						<div className="thank-you">Thank you for contacting us. Our team will get back to you as quickly as possible.</div>
					}
				</div>
			</form>
		);
	}
}

export default ContactForm;
