import { NavLink } from 'react-router-dom';
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';

export function PriceComparison(props){
	return(
		<div className="price-comparison-container">
			<h3>{ props.priceTitle }</h3>
			<section className="price-comparison">
				<div className={ `price-column ${ props.columnOnePop ? 'popular' : '' } `}>
					{ props.columnOnePop
						? <div className="most-popular">Most Popular</div>
						: null
					}
					<div className="price-header">
						<div className="price">
							<div className="dollar-sign">$</div>
							{ props.planOnePrice }
							{ props.perHour ? <div className="per-hour"> / hr</div> : null }
						</div>
						<div className="plan-name">{ props.planOneName }</div>
					</div>
					<div className="divider"></div>
					<div className="feature-list">
						<div className="feature">
							<IoIosCheckmarkCircle className="icon checked-circle" />

							{ props.featureA }
						</div>
						<div className="feature">
							<IoIosCheckmarkCircle className="icon checked-circle" />

							{ props.featureB }
						</div>
						<div className="feature inactive">
							<IoIosCloseCircle className="icon close-circle" />

							<div className="feature-inactive-inner">
								{ props.featureC }
							</div>
						</div>
						<div className="feature inactive">
							<IoIosCloseCircle className="icon close-circle" />

							<div className="feature-inactive-inner">
								{ props.featureD }
							</div>
						</div>
						<div className="feature inactive">
							<IoIosCloseCircle className="icon close-circle" />

							<div className="feature-inactive-inner">
								{ props.featureE }
							</div>
						</div>
						<div className="feature inactive">
							<IoIosCloseCircle className="icon close-circle" />

							<div className="feature-inactive-inner">
								{ props.featureF }
							</div>
						</div>
					</div>
					<NavLink to="/contact">
						<button className="pricing-cta">
							Sign Up Today
						</button>
					</NavLink>
				</div>
				<div className={ `price-column ${ props.columnTwoPop ? 'popular' : '' } `}>
					{ props.columnTwoPop
						? <div className="most-popular">Most Popular</div>
						: null
					}
					<div className="price-header">
						<div className="price">
							<div className="dollar-sign">$</div>
							{ props.planTwoPrice }
							{ props.perHour ? <div className="per-hour"> / hr</div> : null }
						</div>
						<div className="plan-name">{ props.planTwoName }</div>
					</div>
					<div className="divider"></div>
					<div className="feature-list">
						<div className="feature">
							<IoIosCheckmarkCircle className="icon checked-circle" />

							{
								props.featureA2
									? props.featureA2
									: props.featureA
							}
						</div>
						<div className="feature">
							<IoIosCheckmarkCircle className="icon checked-circle" />

							{ props.featureB }
						</div>
						<div className="feature">
							<IoIosCheckmarkCircle className="icon checked-circle" />

							{ props.featureC }
						</div>
						<div className="feature">
							<IoIosCheckmarkCircle className="icon checked-circle" />

							{ props.featureD }
						</div>
						<div className="feature inactive">
							<IoIosCloseCircle className="icon close-circle" />

							<div className="feature-inactive-inner">
								{ props.featureE }
							</div>
						</div>
						<div className="feature inactive">
							<IoIosCloseCircle className="icon close-circle" />

							<div className="feature-inactive-inner">
								{ props.featureF }
							</div>
						</div>
					</div>
					<NavLink to="/contact">
						<button className="pricing-cta">
							Sign Up Today
						</button>
					</NavLink>
				</div>
				<div className={ `price-column ${ props.columnThreePop ? 'popular' : '' } `}>
					{ props.columnThreePop
						? <div className="most-popular">Most Popular</div>
						: null
					}
					<div className="price-header">
						<div className="price">
							<div className="dollar-sign">$</div>
							{ props.planThreePrice }
							{ props.perHour ? <div className="per-hour"> / hr</div> : null }
						</div>
						<div className="plan-name">{ props.planThreeName }</div>
					</div>
					<div className="divider"></div>
					<div className="feature-list">
						<div className="feature">
							<IoIosCheckmarkCircle className="icon checked-circle" />

							{
								props.featureA3
									? props.featureA3
									: props.featureA
							}
						</div>
						<div className="feature">
							<IoIosCheckmarkCircle className="icon checked-circle" />

							{ props.featureB }
						</div>
						<div className="feature">
							<IoIosCheckmarkCircle className="icon checked-circle" />

							{ props.featureC }
						</div>
						<div className="feature">
							<IoIosCheckmarkCircle className="icon checked-circle" />

							{ props.featureD }
						</div>
						<div className="feature">
							<IoIosCheckmarkCircle className="icon checked-circle" />

							{ props.featureE }
						</div>
						<div className="feature">
							<IoIosCheckmarkCircle className="icon checked-circle" />

							{ props.featureF }
						</div>
					</div>
					<NavLink to="/contact">
						<button className="pricing-cta">
							Sign Up Today
						</button>
					</NavLink>
				</div>
			</section>
		</div>
	)
}