import styled from 'styled-components';

export const StyledBurger = styled.button`
	position: absolute;
	top: 20px;
	left: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 2rem;
	height: 2rem;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	z-index: 1;

	@media screen and (max-width: 850px) {
		top: 60px;
		left: 60px;
	}

	@media screen and (max-width: 550px) {
		top: 20px;
		left: 20px;
	}

	@media screen and (min-width: 1400px) {
		top: 60px;
		left: 60px;
	}

	span {
		width: 2rem;
		height: ${({ open }) => open ? '.2rem' : '.25rem'};
		background: ${({ theme, open }) => open ? theme.primaryDark : theme.primaryLight};
		border-radius: 10px;
		-webkit-transition: all 0.5s linear;
		transition: all 0.5s linear;
		position: relative;
		transform-origin: 1px;
		box-shadow: ${({ open }) => open ? 'none' : '2px 2px 2px #000'};

		:first-child {
			transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
		}

		:nth-child(2) {
			opacity: ${({ open }) => open ? '0' : '1'};
			transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
		}

		:nth-child(3) {
			transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
		}
	}
`;
