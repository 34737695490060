export function Testimonial({ clientName, clientTestimonial, imgInfo, serviceProvided }){
	return(
		<div className="testimonial">
			<h3>{ clientName }</h3>
			<img alt={ imgInfo }/>
			<p>{ serviceProvided }</p>
			<p>{ clientTestimonial }</p>
		</div>
	);
}
